// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAytb6qm5PoHz19HBG9ea4SlTKkijIc3HY",
  authDomain: "black-castle-1348f.firebaseapp.com",
  databaseURL: "https://black-castle-1348f.firebaseio.com",
  projectId: "black-castle-1348f",
  storageBucket: "black-castle-1348f.appspot.com",
  messagingSenderId: "539211780630",
  appId: "1:539211780630:web:f3c602d2a5a42dee4fdb37",
  measurementId: "G-PKNW0LJVTX",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
export { db };
